import React from "react";
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import Seo from "../components/seo";

const Kosten = [
  {
    id: 1,
    title: "Psychotherapie",
    definition:
      "Die Psychotherapiekosten (auch Kosten für ein Erstgespräch) richten sich nach der GOP (Gebührenordnung für Psychotherapeuten). Für eine Einzelbehandlung (50 Minuten) wird der 2,3- fache Steigerungssatz (103,87 Euro) vereinbart. Es können zusätzliche Kosten für weitere Diagnostik, Befundberichte und andere Mehraufwendungen entstehen, die auf der Grundlage der GOP abgerechnet werden.",
  },
  {
    id: 2,
    title: "Coaching",
    definition:
      "Die Kosten für ein Coaching lehnen sich bei mir an den Therapiekosten der GOP an. Für eine Einzelbehandlung (50 Minuten) werden 103,87 Euro vereinbart. Die Kosten für Coaching werden generell nicht von der Krankenkasse übernommen, es handelt sich immer um eine Selbstzahlerleistung. Auch hier erfolgt die Rechnungsstellung monatlich.",
  },
  {
    id: 3,
    title: "Paartherapie",
    definition:
      "Die Kosten für eine Paartherapie lehnen sich bei mir ebenfalls an den Therapiekosten der GOP an. Für eine Doppelstunde (100 Minuten) werden 207,74 Euro vereinbart. Auch die Kosten für eine Paartherapie werden generell nicht von der Krankenkasse übernommen. Auch hier erfolgt die Rechnungsstellung monatlich.",
  },
];

function isEven(value) {
  if (value % 2 === 0) return true;
  else return false;
}

const KostenPage = () => (
  <Layout>
    <Seo
      title="Kosten | Praxis für Psychotherapie München | Verhaltenstherapie"
      description="Kostenübernahme der Psychotherapie, Abrechnung über private Krankenversicherungen & Beihilfe, Selbstzahler. Privatpraxis: Im Kostenerstattungsverfahren Übernahme durch geseztliche Krankenversicherung möglich"
    />

    <div className="relative">
      <div className="w-full h-96">
        <StaticImage
          className="w-full h-full"
          src="../assets/images/hero-ueber-mich.jpg"
          alt=""
        />
        <div className="absolute top-0 w-full h-full opacity-30 bg-gradient-to-r from-transparent via-black to-transparent"></div>
        <div className="absolute top-0 flex flex-wrap items-center w-full h-full place-items-center">
          <div className="w-full text-center text-white">
            <h1 className="mb-4 lg:text-4xl">Kosten</h1>
            {/* <div className="px-4 sm:text-lg">"Vertrauensvolle Zusammenarbeit auf Augenhöhe ist mir wichtig."</div> */}
          </div>
        </div>
      </div>
    </div>
    <div className="my-12 generic-page boxed">
      <div className="overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="">
          <dl>
            {Kosten.map((item) => (
              <div
                key={item.id}
                className={
                  !isEven(item.id)
                    ? "bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 auto-cols-min sm:gap-4 sm:px-6"
                    : "px-4 py-5 sm:grid sm:grid-cols-3 auto-cols-min sm:gap-4 sm:px-6"
                }
              >
                <dt className="text-sm font-medium text-gray-500">
                  {item.title}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 max-w-prose">
                  {item.definition}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  </Layout>
);

export default KostenPage;
